.coachingAd {
  text-decoration: none;
  font-family: "Roboto", Sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  fill: #072847;
  color: #072847;
  background-color: #05ffc7;
  padding: 7px 20px 7px 20px;
  display: block;
  text-align: center;

  &__headline, &__text {
    display: block;
    text-decoration: none;
    line-height: 1.5em;
  }

  &__headline {
    font-weight: 800;
  }

  &__text {

  }
}
