.splash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 0;
  &__headline {
    margin-bottom: 0.8em;
  }

  &__video {
    margin-bottom: 1em;
    width: 100%;
    position: relative;
    padding-top: 56.25%;
    > iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &__subline {
    font-family: $textFont;
    font-size: 15px;
    color: var(--headlineColor);
    text-align: center;
    font-weight: bold;
  }

  &__massiveHeadline {
    margin-top: 10px;
    margin-bottom: 0;
  }

  &__button {
    font-family: $primaryFont;
    background: var(--buttonBackgroundColor);
    color: var(--buttonTextColor);
    font-weight: bold;
    border-radius: 3px;
    border: none;
    padding: 10px 20px;
    margin: 20px auto 0 auto;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 15px;

    &:hover {
      background: var(--buttonBackgroundHoverColor);
      color: var(--buttonTextHoverColor);
    }
  }
}
