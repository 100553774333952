$primaryColor: #ff6d70;
$lightPrimaryColor: #ffd3d4;
$secondaryColor: #0084a1;
$lightSecondaryColor: #98dddf;
$darkSecondaryColor: #006280;
$textColor: #3b3d43;

$answerButtonColors: #00ab9a, #18baa2, #30c9ab, #4ddbb5, #66e8bb;
$chartValueColors: #ff004e, #ff01a2, #d000f6, #6b2bfe, #0800c7;
$chartOuterRingColor: $darkSecondaryColor;
$chartInnerRingColors: #A7EFF2, #9EE7EC, #94DFE5, #8BD8DF, #82D0D9, #79C8D3, #6FC0CC, #66B8C6, #5DB1C0, #53A9B9;

$primaryFont: 'Jost', sans-serif;
$textFont: 'Roboto Condensed', sans-serif;
