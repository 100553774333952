.socialShareButtons {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 25px;

  &__item {
    width: 25px;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  svg {
    width: 100%;
  }
}
