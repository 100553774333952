.yourCode {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin: auto;
  }

  &__label {
    font-size: 14px;
    color: var(--textColor);
    line-height: 1.3;
    white-space: nowrap;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  &__input {
    margin-bottom: 0;
    padding: 6px;
    width: 120px;
    color: var(--codeInputTextColor);
    margin-top: 0;
  }

  &__formContent {
    display: flex;
    flex-direction: row;
  }

  &__button {
    background: var(--copyCodeButtonBackgroundColor);
    color: var(--copyCodeButtonTextColor);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    height: 43px;
    width: 43px;

    &:hover {
      background: var(--copyCodeButtonBackgroundHoverColor);
      color: var(--copyCodeButtonTextHoverColor);
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__copyNotice {
    text-align: center;
    color: green;
    padding: 10px 0;
  }
}
