.header {
  background-image: linear-gradient(90deg, var(--headerGradientColorLeft) 0%, var(--headerGradientColorRight) 100%);
  line-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  color: var(--headerTextColor);

  &__logo {
    width: 200px;
  }

  &__coopText {
    font-size: 0.8rem;
    margin-top: 1rem;
    line-height: 1rem;
    font-family: $textFont;
  }
}
