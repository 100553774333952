.navigation {

  &Buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 13px;
    text-transform: uppercase;
    font-family: $primaryFont;
    font-weight: bold;
    color: var(--navigationButtonColor);
    margin-top: 0px;

    &--withPaddingTop {
      padding-top: 15px;
    }

    @include md {
      font-size: 16px;
    }

    a {
      text-decoration: none;
    }
  }

  &Button {
    display: flex;
    align-items: center;
    transition: all 0.3s ease-out;
    cursor: pointer;

    &--up {
      flex-direction: column;
      color: $textColor;
    }

    &.is-disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &__arrow {
      width: 22px;
      height: 22px;
      margin-right: 5px;

      &--flipped {
        transform: scaleX(-1);
        margin-right: 0;
        margin-left: 5px;
      }
    }

    &__label {
      text-decoration: none;
    }

    &--up &__arrow {
      transform: rotate(90deg);
      margin-bottom: 3px;
      margin-right: 0;
    }
  }
}
