.segmentControl {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 40px 0;

  &__item {
    flex: 1;
    background: var(--categoryButtonBackgroundColor);
    color: var(--categoryButtonTextColor);
    font-size: 6px;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &.is-active {
      background: var(--categoryButtonActiveBackgroundColor);
      color: var(--categoryButtonActiveTextColor);
    }

    &:not(:last-child) {
      margin-right: 5px;
    }

    @include md {
      font-size: 11px;
    }
  }

  &__icon {
    flex: 1;
    svg {
      height: 25px;
      margin: 10px 0;

      @include md {
        height: 40px;
      }
    }
  }
}
