.segmentPercentileDisplay {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0;

  &__label {
    font-family: $textFont;
    font-weight: bold;
    color: $secondaryColor;
    font-size: 16px;

    &--left {
      color: var(--barGradientColorLeft);
    }

    &--right {
      color: var(--barGradientColorRight);
    }
  }

  &__line {
    position: relative;
    flex: 1;
    height: 3px;
    background-image: linear-gradient(90deg, var(--barGradientColorLeft) 0%, var(--barGradientColorRight) 100%);
    margin: 0 8px;

    &:before, &:after {
      content: '';
      display: block;
      width: 3px;
      height: 18px;
      background: $secondaryColor;
      position: absolute;
      top: -7px;
    }

    &:before {
      background: var(--barGradientColorLeft);
    }

    &:after {
      right: 0;
      background: var(--barGradientColorRight);
    }
  }

  &__indicator {
    position: absolute;
    color: var(--barIconColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    top: -14px;
    transform: translateX(-50%);
    z-index: 2;
  }

  &__icon {
    width: 30px;
  }

  &__topLabel, &__bottomLabel {
    position: absolute;
    width: 80px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &__topLabel {
    top: -20px;
  }

  &__bottomLabel {
    bottom: -20px;
  }
}
