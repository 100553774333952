.questionnaire {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 25px;

  &Headline {
    margin-bottom: 0.5em !important;
    color: var(--questionnaireHeadlineColor) !important;
  }

  &Form {
    list-style: none;
    padding: 0;
    margin: 35px 0 0;
  }

  &Option {
    $selector: &;
    &:nth-child(1) { background: var(--answerButtonColor1); }
    &:nth-child(2) { background: var(--answerButtonColor2); }
    &:nth-child(3) { background: var(--answerButtonColor3); }
    &:nth-child(4) { background: var(--answerButtonColor4); }
    &:nth-child(5) { background: var(--answerButtonColor5); }

    border-radius: 10px;
    padding: 0 20px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--answerButtonTextColor);

    &__right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__checkbox {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background: white;
      margin-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        display: block;
        content: '';
        background: var(--answerSelectionRectangleColor);
        width: 0;
        height: 0;
        transition: all 0.1s ease-out;
      }

      @at-root #{$selector}.is-active &:before {
        width: 50%;
        height: 50%;

      }
    }

    &__label {
      width: 20px;
    }
  }

  &Progress {
    position: relative;
    height: 21px;
    border-radius: 11px;
    background: var(--answerProgressBackgroundColor);
    margin: 60px 0;

    &__icon, &__label {
      position: absolute;
      transform: translate(-50%, -50%);
      color: var(--answerProgressColor);
      transition: all 0.3s ease-out;
    }

    &__icon {
      width: 40px;
      height: 40px;
      top: 50%;
      left: 0;
      color: var(--answerProgressColor);
    }

    &__label {
      text-align: center;
      width: 40px;
      top: 100%;
      margin-top: 20px;
    }
  }
}
