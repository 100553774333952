.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
}

.addOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  padding: 20px;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 3;

  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  &__headline {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &__button {
    margin-top: 0.5rem;

    font-family: $primaryFont;
    background: var(--buttonBackgroundColor);
    color: var(--buttonTextColor);
    font-weight: bold;
    border-radius: 3px;
    border: none;
    padding: 10px 20px;
    margin: 20px auto 0 auto;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 15px;

    &:hover {
      background: var(--buttonBackgroundHoverColor);
      color: var(--buttonTextHoverColor);
    }
  }

  &__closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    color: darkgrey;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 3;

    > svg {
      width: 18px;
      height: 18px;
    }
  }
}
