.form {
  display: flex;
  flex-direction: column;
}

.textInput {
  border: 1px solid var(--textInputBorderColor);
  font-family: $primaryFont;
  font-size: 20px;
  text-align: center;
  color: var(--textInputTextColor);
  padding: 10px;
  margin-bottom: 0.3rem;

  &::placeholder {
    color: var(--textInputPlaceholderColor);
  }

  &--upcase {
    text-transform: uppercase;
  }

  &--full {
    width: 100%;
  }
}

.formButton {
  background: var(--buttonBackgroundColor);
  color: var(--buttonTextColor);
  border: none;
  padding: 10px;
}

[type="checkbox"] {
  position: absolute;
  left: 10px;
  top: 50%;
  z-index: 0;
  opacity: 0.001;
  width: 1px;
  height: 1px;
}
[type="checkbox"] + label {
  display: block;
  text-align: left;
  &, a {
    color: var(--textColor);
  }
}
[type="checkbox"] + label::before {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid var(--textInputBorderColor);
  background-color: #fff;
  display: block;
  content: "";
  float: left;
  margin-right: 10px;
  z-index: 5;
  position: relative;
}
[type="checkbox"]:checked+label::before {
  box-shadow: inset 0px 0px 0px 3px #fff;
  background-color: var(--textInputBorderColor);
}

.formRow {
  position: relative;
  &--narrow {
    max-width: 300px;
    margin: auto;
  }

  &--moreSpacing {
    margin-top: 5px;
    padding-bottom: 8px;
  }
}
