@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

@import '_variables';
@import '_generic';
@import '_typography';
@import '_loadingIndicator';
@import '_header';
@import '_footer';
@import '_app';
@import '_screen';
@import '_questionnaire';
@import '_navigation';
@import '_headline';

@import '_result';
@import '_splash';
@import '_final';
@import '_radar';
@import '_socialShareButtons';
@import '_segmentControl';
@import '_segmentDetail';
@import '_segmentPercentileDisplay';
@import '_addOverlay';
@import '_inputs';
@import '_yourCode';
@import '_signupForm';
@import '_coachingAd';
@import '_page404';
