.radar {
  margin-bottom: 25px;

  &__background {
    width: 100%;
  }

  &__chart {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__control {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  &__addButton {
    width: 30px;
    height: 30px;
    color: var(--chartAddButtonBackgroundColor);
    cursor: pointer;
  }

  &__top {
    position: relative;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__tooltip {
    position: absolute;
    background: white;
    padding: 5px;
    border-radius: 3px;
    transform: translate(10px, 10px);
    color: var(--textColor);
  }
}

.radarKey {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column-reverse;

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    &Square {
      width: 15px;
      height: 15px;
      margin-right: 10px;

      @include md {
        width: 30px;
        height: 30px;
      }
    }

    &Label {
      font-size: 16px;
      font-family: $primaryFont;
      font-weight: bold;

      @include md {
        font-size: 24px;
      }
    }
  }
}
