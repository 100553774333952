.text {
  font-size: 14px;
  color: var(--textColor);
  line-height: 1.3;
  font-weight: 400;

  &--highlighted {
    color: var(--highlightedTextColor);
  }

  &--faded {
    opacity: 0.7;
  }

  &--dark {
    color: #072847;
  }

  &--grey {
    color: #3b3d43;
  }

  &--centered {
    text-align: center;
  }

  &--larger {
    font-size: 18px;
  }

  @include md {
    font-size: 14px;

    &--larger {
      font-size: 18px;
    }
  }
}


.link {
  color: #ff004e;
  text-decoration: none;
  font-family: $textFont;
}

.paragraph {
  margin-block-start: 1em;
  margin-block-end: 1em;
}
