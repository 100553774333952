.appContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include md {
    max-width: 720px;
    margin: auto;
  }
}
