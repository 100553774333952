.footer {
  width: 100%;
  font-size: 0;
  margin-top: 40px;
  color: var(--headerTextColor);

  &__waveContainer {
    height: 100px;
    overflow: hidden;
    width: 100%;
  }

  &__wave {
    fill: var(--footerBackgroundColor);
    height: 100px;
    min-height: 100px;
    width: 100%;
    transform: scaleY(-1) scaleX(5) translateX(30%);
  }

  &__links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__content {
    background: var(--footerBackgroundColor);
    font-family: $textFont;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
  }

  &__coopText {
    font-size: 0.7rem;
    color: var(--footerCoopTextColor);
  }

  &__logo {
    width: 10rem;
    margin-bottom: 1rem;
    margin-top: 0.8rem;
    height: auto;
    color: var(--footerLogoColor);
  }

  &__link {
    a {
      text-decoration: none;
      color: var(--footerTextColor);
      padding: 0 5px;
    }
    &:not(:last-child):after {
      display: inline-block;
      content: '|';
      color: var(--footerTextColor);
    }
  }
}
