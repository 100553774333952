.segmentDetail {
  margin-bottom: 40px;
  &__descriptionText {
    color: var(--textColor);
    font-size: 14px;

    > p {
      margin-bottom: 0.5em;
    }
  }

  &__percentileText {
    color: var(--percentileTextColor);
  }

  &__headline {
    margin-top: 0;
    margin-bottom: 10px;
  }
}
