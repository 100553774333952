.headline {
  text-align: center;
  font-family: $primaryFont;
  font-weight: 700;
  color: var(--headlineColor);
  font-size: 25px;
  line-height: 1.2;
  margin: 30px 0;

  @include md {
    font-size: 35px;
    margin: 50px 0;
  }

  &--huge {
    font-size: 18pt;
    @include md {
      font-size: 28pt;
    }
  }

  &--massive {
    font-size: 70px;
    text-transform: uppercase;
  }

  &--left {
    text-align: left;
  }

  &--upcase {
    text-transform: uppercase;
  }
}

.subline {
  font-family: $textFont;
  color: var(--headlineColor);
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 0;
}
