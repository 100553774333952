.loadingIndicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;

  &__ellipsis {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
  }
  &__ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #072847;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  &__ellipsis div:nth-child(1) {
    left: 8px;
    animation: loadingIndicator__ellipsis1 0.6s infinite;
  }
  &__ellipsis div:nth-child(2) {
    left: 8px;
    animation: loadingIndicator__ellipsis2 0.6s infinite;
  }
  &__ellipsis div:nth-child(3) {
    left: 32px;
    animation: loadingIndicator__ellipsis2 0.6s infinite;
  }
  &__ellipsis div:nth-child(4) {
    left: 56px;
    animation: loadingIndicator__ellipsis3 0.6s infinite;
  }
}

@keyframes loadingIndicator__ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loadingIndicator__ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loadingIndicator__ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
